import request from '@/service/request'
/*  用水设置 api */

/* 教师用水设置 page接口
*  @params/keyword 关键字
* */
export const pageTeaWaterUseSet = (params) => {
  return request.post('/handle/waterUseSet/pageTeaWaterUseSet', params)
}


/* 学生用水设置 page接口
*  @params/keyword 关键字
* */
export const pageStuWaterUseSet = (params) => {
    return request.post('/handle/waterUseSet/pageStuWaterUseSet', params)
  }

/* 用水设置 更新用户用水限制
*  @params/userId 
*  @params/waterUseLimit 用水限制量
* */
export const refreshWaterUseSetApi = (params) => {
  return request.post('/handle/waterUseSet/update', params)
}


