<template>
    <el-dialog :title="`更新${preTitle}用水标准`" :visible.sync='show' :close-on-click-modal='false' width='600px'>
        <el-form ref="form" :rules="rules" :model="formData" label-width='100px'>
            <el-row>
                <el-col :span="24">
                    <el-form-item :label="`${preTitle}用水标准`" prop="sandard">
                        <tool-tips-group tips-content="更新后最晚次日生效">
                            <el-input v-model="formData.sandard" maxlength="3"></el-input>
                        </tool-tips-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type="primary" @click="save(`用水标准`, refreshWaterSandrad)">确 定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import toolTipsGroup from '../../../../components/common/components/toolTipsGroup.vue'
import { addParams } from '@/api/sysParams'
export default {
    components: { toolTipsGroup },
    mixins: [dialog],
    props: {
        formData: {
            type: Object,
            default: () => ({
                flag: null,
                id: null,
                sandard: null,
            })
        }
    },
    data() {
        return {
            preTitle: this.formData.flag === 'stu' ? '学生' : '教师',
            rules: {
                sandard: this.$store.state.commonValidate.validatorNumberObj
            }
        };
    },
    methods: {
        // teacherDayWaterUseLiter 教师
        // dayWaterUseLiter 学生
        refreshWaterSandrad() {

            if (this.formData.flag == 'stu') {
                addParams({
                    id: this.formData.id,
                    paramKey: "dayWaterUseLiter",
                    paramValue: this.formData.sandard
                })
            } else if (this.formData.flag == 'teacher') {
                addParams({
                    id: this.formData.id,
                    paramKey: "teacherDayWaterUseLiter",
                    paramValue: this.formData.sandard
                })
            }
        }
    }
}
</script>

<style></style>