<template>
    <el-dialog :title="`更新用水量(${waterInfo.realName}${waterInfo.dormitoryName ? '--' + waterInfo.dormitoryName : ''})`"
        :visible.sync='show' :close-on-click-modal='false' width='600px'>
        <el-form ref="form" :rules="rules" :model="formData" label-width='70px'>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="用水量" prop="waterUseLimit">
                        <tool-tips-group tips-content="更新后最晚次日生效">
                            <el-input v-model="formData.waterUseLimit" maxlength="3"></el-input>
                        </tool-tips-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type="primary" @click="save('宿舍用水量', refreshWaterUseSetApi)">确 定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import toolTipsGroup from '../../../../components/common/components/toolTipsGroup.vue'
import { refreshWaterUseSetApi } from '@/api/finance-manage/waterUseSet-api.js'
export default {
    components: { toolTipsGroup },
    mixins: [dialog],
    props: {
        waterInfo: {
            type: Object,
            default: () => ({
                userId: null,
                dormitoryName: null,
                realName: null,
                waterUseLimit: null
            })
        }
    },
    data() {
        return {
            formData: {
                waterUseLimit: null
            },
            preTitle: '编辑',
            rules: {
                waterUseLimit: this.$store.state.commonValidate.validatorNumberObj
            }
        }
    },
    mounted() {
        this.formData = this.waterInfo
    },
    methods: {
        refreshWaterUseSetApi
    }
}
</script>

<style></style>